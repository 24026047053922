import { Link } from "react-router-dom";
import { motion } from "framer-motion"

export default function HomePage({ projects }) {

  return (


    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}>
     <h2>HomePage</h2>
      <ul>
      {projects.length ? projects.map((project,i) => {
      return (
        <li key={project.id}>
          <Link to={`/projects/${project.slug}`}>
            <img style={{ maxWidth : 500 }} src={project.thumbnail} alt={ project.title } />
            <span>{ project.title }</span>
          </Link>
        </li>
      )
    }) : <li>No project for the moment </li>}
      </ul>
    </motion.div>


  );
}
