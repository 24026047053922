import { motion } from "framer-motion"

export default function Project({ project }) {
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}>
      <h2>{project.title}</h2>
      <img style={{ maxWidth: 500 }} src={`../${project.thumbnail}`} alt={project.title} />

    </motion.div>
  );
}
