import styles from './App.module.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Homepage from "./pages/homepage/homepage";
import Project from "./pages/project/project";
import projects from "./datas/projects.json"


function App() {
  return (
    <div className={styles.App}>
      <AnimatePresence>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage projects={projects} />} />
            { projects.length && projects.map((project,i) => {
              return <Route key={ project.id } path={ `/projects/${project.slug}` } element={<Project project={ project } />} />
            })}
          </Routes>
        </BrowserRouter>
      </AnimatePresence>
    </div>
  );
}

export default App;
